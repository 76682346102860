/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b3fd7bbc-e5a3-48fc-ad0a-a6084fd209e5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_wLOfnQciW",
    "aws_user_pools_web_client_id": "4ifesph61jb753vh045lhu2rv7",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ckm-s3-customer-upload110740-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "s3uploaderui-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1ubwefsp437y6.cloudfront.net"
};


export default awsmobile;
